<template>
  <div class="cameraManagementConfig">
    <CameraManagementConfigDisplay
      :installation-id="installationId"
      :lane-number="laneNumber"
    />
  </div>
</template>

<script>
export default {
  name: "CameraManagementConfig",
  components: {
    CameraManagementConfigDisplay: () => import('@/components/Config/CameraManagement/CameraManagementConfigDisplay.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: 'Camera Management Config'
    }
  }
}
</script>
